<template>
  <div>
    <div class="row justify-content-end">
      <div class="col-auto">
        <label for=""></label>

        <button @click="refresh()" class="btn btn-info">
          <i class="bi bi-arrow-clockwise"></i> Actualiser
        </button>
      </div>
      <div class="col-auto">
        <label for=""></label>

        <button @click="print()" class="btn btn-secondary border">
          <i class="bi bi-printer fs-4"></i> Imprimer
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Nom Complet</th>
          <th scope="col">Montant</th>
          <th scope="col">Date</th>
          <th scope="col">sale</th>
          <th scope="col">payment</th>
          <th scope="col">Status</th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(credit, index) in credits" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ credit.customer_reference }}
          </td>
          <td>
            <span v-if="credit.customer">
              {{ credit.customer.fullName }}
            </span>
          </td>
          <td>{{ parseFloat(credit.amount).toFixed(2) | formatWithSpaces }}</td>
          <td>{{ credit.date }}</td>
          <td>
            <span>
              {{ credit.sale_reference }}
            </span>
          </td>
          <td>
            <span>
              {{ credit.payment_reference }}
            </span>
          </td>
          <td>
            <span>
              <select :value="credit.status" class="border-0" :disabled="true">
                <option :value="'pending'">En attente</option>
              </select>
            </span>
          </td>

          <td class="p-0 m-0">
            <span class="d-flex">
              <button
                @click="paySale(credit.reference)"
                class="btn btn-primary px-2 mx-1"
              >
                Régler facture
              </button>

              <button
                @click="refund(credit.reference)"
                class="btn btn-primary px-2 mx-1"
              >
                Remboursement
              </button>
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("credit", {
      credits: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch(
      "credit/getCustomerCreditBalance",
      this.$route.params.customer_reference
    );
  },
  methods: {
    async search(value) {
      return await this.$store.commit("credit/search", value);
    },
    async refresh() {
      this.$store.dispatch(
        "credit/getCustomerCreditBalance",
        this.$route.params.customer_reference
      );
    },
    async paySale(reference) {
      this.$store.dispatch("credit/paySale", reference);
    },
    async refund(reference) {
      this.$store.dispatch("credit/refund", reference);
    },
  },
  filters: {
    formatWithSpaces(value) {
      // Convert the number to a string and apply spaces as thousands separators
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    },
  },
};
</script>
